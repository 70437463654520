<template>
    <v-container grid-list-md>
        <div v-if="catalogs && catalogs != ''">
            <v-layout wrap>
                <v-flex xs12 v-for="catalog in catalogs">
                    <LineItem :to="{name: 'UpdateCatalog', params:{id:catalog._id, toto:'test'}}" :id="catalog._id" :selectId="catalog._id" :title="catalog.name" :author="catalog.authorName" :description="'Contain ' + catalog.products.length + ' products.'" v-on:deleteItem="dialogConfirmDelete=true;idCatalogToDelete=$event" :edit="false" :bottomInfo="catalog.language" />
                </v-flex>
            </v-layout>
        </div>
        <v-layout v-else-if="catalogs == ''" justify-center align-center style="height: 75vh">
            <h1>No catalogs found</h1>
        </v-layout>
        <v-layout v-else justify-center align-center style="height: 90vh">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-layout>
        <v-layout row justify-center>
            <v-dialog v-model="dialogConfirmDelete" width="500">
                <v-card>
                    <v-card-title class="headline error" style="color:white" primary-title>
                        Warning
                    </v-card-title>
                    <v-card-text>
                        You are about to remove a catalog.
                        <br /><br />
                        <b>Are you sure ?</b>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" outline @click="dialogConfirmDelete = false;">
                            Cancel
                        </v-btn>
                        <v-btn color="error" @click="deleteForever">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" persistent max-width="290">
                <template v-slot:activator="{ on }">
                    <v-btn fab fixed bottom right dark color="primary" v-on="on" icon>
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="headline">Create Catalog</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-container>
                                <v-layout row wrap>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field v-model="newCatalogName" :rules="[rules.required]" label="Name"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-autocomplete :items="languages" item-text="name" item-value="code" v-model="lang" label="Language"></v-autocomplete>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outline color="primary" @click="dialog = false">Cancel</v-btn>
                        <v-btn :disabled="!newCatalogName || !lang" color="primary" depressed @click="dialog = false" :to='{name: "NewCatalog", params:{lang, name: newCatalogName}}'>Compose</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
import LineItem from "../components/LineItem.vue"
import TileItem from "../components/TileItem.vue"

import { apiGet, apiDelete } from "../modules/query.js"
import languages from "../assets/languages.json"
export default {
    components: {
        TileItem,
        LineItem
    },
    data: () => ({
        newCatalogName: "",
        displayTile: false,
        languages: languages,
        lang: 'en',
        toDeleteId: null,
        catalogs: null,
        dialog: false,
        idCatalogToDelete: null,
        dialogConfirmDelete: false,
        rules: {
            required: value => !!value || 'Required.',
        }

    }),
    computed: {
        catalogText: function() {
            return "Contain " + this.catalog.products.length + " products."
        },

    },
    methods: {
        async deleteForever() {
            try {
                await apiDelete("/api/catalogs/" + this.idCatalogToDelete)
                this.catalogs = this.catalogs.filter(e => e._id != this.idCatalogToDelete)
                this.idCatalogToDelete = null
                this.dialogConfirmDelete = false
            } catch (e) {
                console.log(e)
            }
        }
    },
    async created() {
        try {
            let datatest = await apiGet("/api/data")
            console.log("datatest: ", datatest);

            let catalogs = await apiGet("/api/catalogs")
            console.log("catalogs: ", catalogs)
            this.catalogs = catalogs.data
        } catch (e) {
            console.log(e)
        }

    }
}
</script>