const tokenStorageKey = 'apiToken';

const onAuthChangeCbs = [];

export function getToken() {
    return localStorage.getItem(tokenStorageKey);
}

export function setToken(tokenVal) {
    localStorage.setItem(tokenStorageKey, tokenVal);

    let token = getToken();

    onAuthChangeCbs.forEach(cb => cb(token));

    return token;
}

export function onAuthChange(cb) {
    onAuthChangeCbs.push(cb);
}