<template>
  <v-card>
    <v-img height="200px" :src="img" contain></v-img>
    <!-- <v-card-title primary-title>
    <h4 class="headline mb-0">{{title}}</h4>
    </v-card-title>-->
    <v-card-text style="height: 150px; overflow: scroll">
      <h4 class="headline mb-0">{{title}}</h4>
      <span v-if="subhead" class="grey--text">{{subhead | capitalize}}</span>
      <br />
      <br />
      <span v-if="description">{{description}}</span>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-checkbox v-if="!!selectedItems" v-model="selectedItems" :value="selectId"></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn icon v-if="edit" @click="$emit('editItem', id)">
        <v-icon>create</v-icon>
      </v-btn>
      <v-btn icon v-if="remove" @click="$emit('deleteItem', selectId)">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    id: String,
    selectId: String,
    to: Object,
    subhead: String,
    img: String,
    title: String,
    author: String,
    description: String,
    selected: { type: Array, default: null },
    bottomInfo: String,
    remove: { type: Boolean, default: true },
    edit: { type: Boolean, default: true }
  },
  model: {
    prop: "selected",
    event: "update:selected"
  },
  data: function() {
    return {
      dialog: false
    };
  },
  computed: {
    selectedItems: {
      get: function() {
        return this.selected;
      },
      set: function(val) {
        this.$emit("update:selected", val);
      }
    }
  }
};
</script>
<style scoped>
</style>