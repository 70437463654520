const axios = require('axios');
const { getToken, setToken } = require('./token.js');

// TODO: Dynamic host ? Need search on heroku.

console.log("ENV: ", process.env)
let host = 'http://localhost:3000'
//let host = 'https://ysl-rates-review-dev.herokuapp.com'
if (process.env.VUE_APP_API_URL)
    host = process.env.VUE_APP_API_URL;


export function apiGet(url, data, params) {
    return axios({
        url: host + url,
        data: data,
        params: params,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export function apiPost(url, data, params) {
    return axios({
        url: host + url,
        data: data,
        params: params,
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export function apiPut(url, data, params) {
    return axios({
        url: host + url,
        data: data,
        params: params,
        method: 'put',
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export function apiDelete(url, data, params) {
    return axios({
        url: host + url,
        data: data,
        params: params,
        method: 'delete',
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

let user = null;

export function getSelf() {
    return new Promise((resolve, reject) => {
        if (!user) {
            apiGet('/api/self').then(result => {
                user = result.data;
                resolve(user);
            }).catch(reject);
        } else resolve(user);
    });
}

export function logout() {
    user = null;
    setToken('');
}