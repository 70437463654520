<template>
    <v-card>
        <v-toolbar height="36" color="primary" dark>
            <v-rating color="white" small dense readonly v-model="comment.rating || comment.src.rating"></v-rating>
            <v-spacer></v-spacer>
            <v-flex xs2>{{comment.lang_short || comment.language}}</v-flex>
            <v-icon small v-if="isTranslation" dark>translate</v-icon>
        </v-toolbar>
        <v-card-text class="comment-card-text">{{comment.comment || comment.text}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-layout align-center>
                <v-spacer></v-spacer>
                <v-btn v-if="isBookmarked && catalogId" icon @click="unbookmarkComment">
                    <v-icon>bookmark</v-icon>
                </v-btn>
                <v-btn v-if="!isBookmarked && catalogId" icon @click="bookmarkComment">
                    <v-icon>bookmark_border</v-icon>
                </v-btn>
                <span class="timestamp">
                    {{comment.src.split('/')[2]}} / {{comment.timestamp.split('T')[0]}}
                </span>
                <!-- <v-btn icon :to="'/translate/' + comment._id">
          <v-icon>translate</v-icon>
        </v-btn>-->
                <v-checkbox :true-value="selectedForTranslation" v-if="!comment.src.rating" class="shrink mt-0" hide-details :value="selectedForTranslation" @change="selectComment"></v-checkbox>
            </v-layout>
        </v-card-actions>
    </v-card>
</template>
<script>
import { apiPut } from "../modules/query.js";

export default {
    props: {
        comment: Object,
        catalogId: String,
        isTranslation: {
            type: Boolean,
            default: false
        },
        isSelected: Boolean
    },
    data: function() {
        return {
            bookmarkComments: [],
            isBookmarked: this.comment.isBookmarked,
            selectedForTranslation: this.isSelected || false
        };
    },
    methods: {
        bookmarkComment: function() {
            this.isBookmarked = true;

            apiPut('/api/catalogs/' + this.catalogId + '/bookmark', {
                commentId: this.comment._id
            }).then(result => {
                console.log(result);
            }).catch(console.log);

            this.$emit('bookmark', this.comment._id);
        },
        unbookmarkComment: function() {
            this.isBookmarked = false;

            apiPut('/api/catalogs/' + this.catalogId + '/unbookmark', {
                commentId: this.comment._id
            }).then(result => {
                console.log(result);
            }).catch(console.log);

            this.$emit('unbookmark', this.comment._id);
        },
        selectComment: function(event) {
            // console.log("select comment",event, this.comment._id)
            let checkState = event ? true : false;
            this.$emit("translationSelect", checkState, this.comment._id);
        }
    },
    watch: {
        'comment.isBookmarked': function() {
            this.isBookmarked = this.comment.isBookmarked;
        },
        isSelected: function() {
            this.selectedForTranslation = this.isSelected;
        }
    }
};
</script>
<style scoped>
.timestamp {
    float: left;
    margin: 0 auto;
    position: absolute;
}
.comment-card-text {
    height: 160px;
    overflow: scroll;
}
</style>