<template>
    <v-container>
        <v-layout justify-center align-center>
            <v-flex xs10>
                <CardLogin v-if="!signupToken" />
                <CardSignup v-if="signupToken" />
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import CardLogin from '../components/CardLogin.vue'
import CardSignup from '../components/CardSignup.vue'

export default {
    data: function() {
        return {};
    },
    components: {
        CardLogin,
        CardSignup
    },
    computed: {
        signupToken: function() {
            return this.$route.query.token;
        }
    }
}
</script>