<template>
    <v-card class="elevation-12">
        <v-toolbar>
            <v-toolbar-title>Select password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form>
                Hello <b>{{username}}</b>, please select your password.
                <v-text-field persistent-hint hint="At least 5 characters" id="password" prepend-icon="lock" name="password" label="Password" type="password" v-model="password" @keydown.enter="signup"></v-text-field>
                <v-text-field :error="password !== confirmPassword" id="confirmPassword" prepend-icon="lock" name="confirmPassword" label="Confirm Password" type="password" v-model="confirmPassword" @keydown.enter="signup"></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn @click="signup">Signup</v-btn> -->
            <v-btn :disabled="!checkPassword" color="primary" @click="signup">Signup</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { apiGet, apiPost } from '../modules/query.js'
import storage, { setToken, getToken } from '../modules/token.js'

export default {
    name: 'CardSignup',
    data: function() {
        // Set token for request
        if (this.$route.query.token) setToken(this.$route.query.token);

        apiGet('/api/self').then(result => {
            this.username = result.data.username;
        }).catch(err => {
            this.$router.push('/login');
        })

        return {
            username: '',
            password: '',
            confirmPassword: ''
        }
    },
    methods: {
        signup: function() {
            if (this.checkPassword) {
                apiPost('/api/signup/password', {
                    password: this.password,
                }).then(res => {
                    setToken(res.data);
                    this.$router.push('/catalogs');
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    },
    computed: {
        checkPassword: function() {
            return (this.password.length >= 5) && (this.password === this.confirmPassword);
        }
    }
}
</script>