<template>
  <div>
    <v-layout v-if="products && products.length" wrap>
      <!-- <v-spacer></v-spacer> -->
      <v-flex offset-xs10 xs2 text-xs-right v-if="!hideDisplayChoice">
        <!-- <v-flex xs2 text-xs-right> -->
        <!--              <v-btn color="white" icon @click="displayTile = false">
                    <v-icon>reorder</v-icon>
                </v-btn>
                <v-btn color="white" icon @click="displayTile = true">
                    <v-icon>view_module</v-icon>
        </v-btn>-->
        <!-- </v-flex> -->
      </v-flex>
      <v-flex xs12 v-if="!displayTile">
        <v-layout class="products-layout" wrap>
          <v-flex xs12 v-for="product in products" :key="'line' + product._id">
            <LineItem
              :selectId="product.ean"
              :id="product._id"
              v-model="internalSelected"
              v-on:deleteItem="$emit('remove',$event)"
              v-on:editItem="$emit('edit',$event)"
              :title="product.name"
              :subhead="'ean: ' + product.ean"
              :img="product.s3_packshot"
              :description="product.subtitle"
              :edit="edit"
              :remove="remove"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout class="products-layout" wrap>
          <v-flex xs3 v-for="product in products" :key="'tile' + product._id">
            <TileItem
              :selectId="product.ean"
              :id="product._id"
              v-model="internalSelected"
              v-on:deleteItem="$emit('remove',$event)"
              v-on:editItem="$emit('edit',$event)"
              :title="product.name"
              :subhead="'ean: ' + product.ean"
              :img="product.s3_packshot"
              :description="product.subtitle"
              :edit="edit"
              :remove="remove"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center align-center v-else>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-layout>
  </div>
</template>
<script>
//:title="" :author="" :description=""
import LineItem from "./LineItem.vue";
import TileItem from "./TileItem.vue";

export default {
  components: {
    TileItem,
    LineItem
  },
  props: {
    selectId: { type: String, default: "_id" },
    remove: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    selected: Array,
    products: Array,
    hideDisplayChoice: { type: Boolean, default: false },
    displayTile: Boolean
  },
  model: {
    prop: "selected",
    event: "update:selected"
  },
  data: () => ({
    toDeleteId: null,
    //products: null,
    //selected: [],
    lang: "fr",
    langs: ["en"]
  }),
  computed: {
    internalSelected: {
      get: function() {
        return this.selected;
      },
      set: function(val) {
        this.$emit("update:selected", val);
      }
    }
  }
};
</script>
<style scoped>
.products-layout {
  margin-top: 5px;
}
</style>