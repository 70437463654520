<template>
  <div>
    <v-card>
      <v-container>
        <v-layout align-center row wrap>
          <v-flex xs7>
            <v-layout align-center>
              <v-flex xs11>
                <router-link v-if="to" :to="to">
                  <h3 class="title">{{title}}</h3>
                </router-link>
                <h3 v-else class="title">{{title}}</h3>
                <span v-if="subhead" class="grey--text">{{subhead | capitalize}}</span>
                <div v-if="to && to.name == 'UpdateCatalog'" class="mt-2">{{description}}</div>
              </v-flex>
              <v-flex xs1>
                <i class="grey--text">{{bottomInfo}}</i>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs5>
            <v-layout align-center>
              <v-flex xs8>
                <v-img height="70px" :src="img" contain></v-img>
              </v-flex>
              <v-flex xs4>
                <v-btn icon v-if="edit" @click="$emit('editItem', id)">
                  <v-icon>create</v-icon>
                </v-btn>
                <v-btn icon v-if="remove" @click="$emit('deleteItem', selectId)">
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-checkbox v-if="selected" v-model="selectedItems" :value="selectId"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <div class="text-xs-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline error" style="color:white" primary-title>Warning</v-card-title>
          <v-card-text>
            You are about to delete the catalog XXX.
            <br />
            <b>Are you sure ?</b>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="dialog = false;">Cancel</v-btn>
            <v-btn color="error" @click="$emit('deleteItem', id);dialog = false;">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    selectId: String,
    to: Object,
    img: String,
    title: String,
    subhead: String,
    description: String,
    selected: { type: Array, default: null },
    bottomInfo: String,
    remove: { type: Boolean, default: true },
    edit: { type: Boolean, default: true }
  },
  model: {
    prop: "selected",
    event: "update:selected"
  },
  data: function() {
    return {
      dialog: false
    };
  },
  computed: {
    selectedItems: {
      get: function() {
        return this.selected;
      },
      set: function(val) {
        this.$emit("update:selected", val);
      }
    }
  }
};
</script>