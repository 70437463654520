import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        primary: colors.grey.darken4,
        secondary: colors.grey.darken3,
        accent: colors.grey.darken2
    }
})