<template>
    <v-container>
        <v-layout justify-center align-center v-if="!users.length">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-layout>
        <v-layout v-if="users.length">
            <v-flex xs12>
                <v-card>
                    <v-list two-line>
                        <template v-for="(user, index) in users">
                            <v-list-tile :key="user.title" avatar @click="">
                                <v-list-tile-avatar>
                                    <v-icon :color="user.password ? 'green' : 'grey'">perm_identity</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{user.username}}</v-list-tile-title>
                                    <!-- <v-list-tile-sub-title>{{ user.role }}</v-list-tile-sub-title> -->
                                </v-list-tile-content>
                                <v-flex xs3>
                                    <v-list-tile-action v-if="user.password">
                                        <v-select :readonly="admin.role != 'super admin'" :items="appRoles" label="user role" :value="user.role" @change="changeUserRole($event, user)"></v-select>
                                    </v-list-tile-action>
                                </v-flex>
                                <!-- <v-list-tile-action> -->
                                <!-- <v-btn v-show="!user.password" flat>
                                Send password link
                                <v-icon right color="grey">email</v-icon>
                            </v-btn> -->
                                <v-text-field readonly v-if="user.passwordToken" name="passwordLink" label="Password Link" type="text" :value="passwordLink(user.passwordToken)" @keydown.enter="login"></v-text-field>
                                <!-- </v-list-tile-action> -->
                                <!-- <v-list-tile-action> -->
                                <v-btn :disabled="user.role == 'super admin'" @click="user.deleteModal = true" flat>
                                    Delete
                                    <v-icon right color="grey">delete</v-icon>
                                </v-btn>
                                <!-- </v-list-tile-action> -->
                            </v-list-tile>
                            <v-dialog v-model="user.deleteModal" persistent max-width="600">
                                <v-card>
                                    <v-card-title class="headline">Delete {{user.username}}</v-card-title>
                                    <v-card-text>
                                        Are you sure you want to delete this user?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn outline @click="user.deleteModal = false">Cancel</v-btn>
                                        <v-btn dark color="red" @click="deleteUser(user._id)">Delete</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                    </v-list>
                </v-card>
                <v-dialog v-model="createDialog" persistent max-width="300">
                    <template v-slot:activator="{ on }">
                        <v-btn fab fixed bottom right dark color="primary" v-on="on">
                            <v-icon dark>add</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline">Create new user</v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-container>
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <v-text-field :rules="[rules.required]" v-model="createEmail" label="email"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn outline @click="createEmail = ''; createDialog = false">Cancel</v-btn>
                            <v-btn :disabled="!createEmail" color="primary" @click="createUser">Create</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import { apiGet, apiPost, apiPut, getSelf, apiDelete } from '../modules/query.js';

export default {
    created: function() {
        this.fetchUsers();

        getSelf().then(user => {
            this.admin = user;
        })
    },
    data: function() {
        return {
            users: [],
            createDialog: false,
            createEmail: '',
            appRoles: ['user', 'admin', 'super admin'],
            admin: {},
            rules: {
                required: value => !!value || 'Required.',
            }
        }
    },
    methods: {
        passwordLink: function(passwordToken) {
            return window.location.origin + '/login?token=' + passwordToken;
        },
        fetchUsers: function() {
            apiGet('/api/users').then(res => {
                res.data.forEach(user => {
                    user.deleteModal = false;
                });

                this.users = res.data;
            }).catch(console.log);
        },
        createUser: function() {
            apiPost('/api/signup', {
                username: this.createEmail
            }).then((createdUserToken) => {
                this.fetchUsers();
            }).catch(console.log);

            this.createEmail = '';
            this.createDialog = false;
        },
        deleteUser: function(userId) {
            apiDelete('/api/users/' + userId).then(() => {
                this.fetchUsers();
            }).catch(console.log);
        },
        changeUserRole: function(newRole, user) {
            console.log('changeUserRole', newRole, user);

            apiPut('/api/users/' + user._id, {
                role: newRole
            }).then(result => {
                console.log(result);
            }).catch(console.log);
        }
    }
}
</script>