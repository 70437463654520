import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import VueRouter from 'vue-router'



import Landing from './pages/landing.vue'
import Login from './pages/login.vue'
import Catalogs from './pages/catalogs.vue'
import Catalog from './pages/catalog.vue'
import Products from './pages/products.vue'
import Product from './pages/product.vue'
import Translate from './pages/translate.vue'
import Users from './pages/users.vue'

Vue.use(VueRouter)
Vue.config.productionTip = false

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/login', component: Login },
        { path: '/landing', component: Landing, name: "Landing" },
        { path: '/catalogs', component: Catalogs, name: "Catalogs" },
        { path: '/catalog', component: Catalog, name: "NewCatalog", props: true },
        { path: '/catalog/:id', component: Catalog, name: "UpdateCatalog", props: true },
        { path: '/products', component: Products, name: "Products" },
        { path: '/products/:productId', component: Product, name: "Product" },
        { path: '/translate', component: Translate, name: "TranslateComments" },
        { path: '/users', component: Users, name: "Users" }
    ]
})

Vue.filter('capitalize', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
    render: h => h(App),
    router
}).$mount('#app')