<template>
    <!-- <div class="text-xs-center py-2"> -->
    <!-- <v-container grid-list-md> -->
    <v-layout wrap>
        <v-flex xs6>
            <v-text-field v-model="searchText" class=" mb-0 mx-1" label="Search by name..." solo append-icon="search" @click:append="search" @keydown.enter="search()"></v-text-field>
        </v-flex>
        <v-flex xs6>
            <v-text-field v-model="searchEan" class=" mb-0 mx-1" label="Search by EAN..." solo append-icon="search" @click:append="search" @keydown.enter="search()"></v-text-field>
        </v-flex>
        <v-flex xs4>
            <v-select v-model="selectTheme" @change="search" label="Theme" :items="mainCategs" item-text="name.en_US" item-value="id"></v-select>
        </v-flex>
        <v-flex xs4>
            <v-select v-model="selectCategory" @change="search" label="Category" :items="subCateg" item-text="name.en_US" item-value="id"></v-select>
        </v-flex>
        <v-flex xs4>
            <v-select v-model="selectSubCategory" @change="search" label="Subcategory" :items="thirdCateg" item-text="name.en_US" item-value="id"></v-select>
        </v-flex>
        <v-flex xs4 offset-xs4>
            <v-pagination v-show="pagination" v-model="page" :length="queryProperties.totalPages" :total-visible="6" @input="search"></v-pagination>
        </v-flex>
        <v-spacer />
        <v-flex xs2 align-self-end class="text-xs-right">
            <v-btn class="mt-0" color="white" icon @click="displayTile = false;search()">
                <v-icon>reorder</v-icon>
            </v-btn>
            <v-btn class="mt-0" color="white" icon @click="displayTile = true;search()">
                <v-icon>view_module</v-icon>
            </v-btn>
        </v-flex>
    </v-layout>
    <!-- </v-container> -->
    <!-- </div> -->
</template>
<script>
import _ from 'lodash'
import allCategs from '../assets/categories.json'

export default {
    name: "SearchAndPaginate",
    model: {
        prop: "queryProperties",
        event: "update:queryProperties"
    },
    props: {
        queryProperties: {
            type: Object,
            default: () => ({
                page: 1,
                totalPages: 6,
                searchText: "",
                searchEan: "",
                displayTile: true,
                categories: []
            })
        },
        "total-visible": { type: Number, default: 6 },
        pagination: Boolean
    },
    data: function() {
        return {
            page: this.queryProperties.page,
            totalPages: this.queryProperties.totalPages,
            searchText: this.queryProperties.searchText,
            searchEan: this.queryProperties.searchEan,
            displayTile: this.queryProperties.displayTile,
            selectedTheme: '',
            selectedCategory: '',
            selectedSubcateg: ''
        }
    },
    watch: {
        searchText(val) {
            this.page = 1
        },
        searchEan(val) {
            this.page = 1
        },
        selectedSubcateg(val) {
            this.page = 1
        },

    },
    methods: {

        search() {
            let searchCategs = [];

            if (this.selectedSubcateg) {
                searchCategs = [this.selectedSubcateg];
            } else if (this.selectedCategory) {
                searchCategs = [this.selectedCategory].concat(this.thirdCateg.map(c => c.id))
            } else if (this.selectedTheme) {
                let subCategs = [];

                this.subCateg.forEach(categ => {
                    let allSubcategs = _.filter(allCategs, c => c.parent_id == categ.id).map(c => c.id);
                    // console.log('allSubcategs', allSubcategs);
                    subCategs.push(...allSubcategs);
                });

                subCategs = subCategs.concat(this.subCateg.map(c => c.id));

                searchCategs = [this.selectedTheme].concat(subCategs);
            }

            this.$emit("update:queryProperties", {
                page: this.page,
                totalPages: this.totalPages,
                searchText: this.searchText,
                searchEan: this.searchEan,
                displayTile: this.displayTile,
                categories: searchCategs
            });
        }
    },
    computed: {
        selectTheme: {
            get: function() {
                return this.selectedTheme;
            },
            set: function(value) {
                this.selectedCategory = '';
                this.selectedSubcateg = '';
                this.selectedTheme = value;
                this.page = 1
            }
        },
        selectCategory: {
            get: function() {
                return this.selectedCategory;
            },
            set: function(value) {
                this.selectedSubcateg = '';
                this.selectedCategory = value;
                this.page = 1
            }
        },
        selectSubCategory: {
            get: function() {
                return this.selectedSubcateg;
            },
            set: function(value) {
                this.selectedSubcateg = value;
                this.page = 1
            }
        },
        mainCategs: function() {
            return _.filter(allCategs, categ => {
                return categ.parent_id == null;
            })
        },
        subCateg: function() {
            return _.filter(allCategs, categ => {
                return categ.parent_id == this.selectedTheme;
            });
        },
        thirdCateg: function() {
            return _.filter(allCategs, categ => {
                return categ.parent_id == this.selectedCategory;
            });
        }
    }
}
</script>