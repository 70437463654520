<template>
    <v-card class="elevation-12">
        <v-toolbar>
            <v-toolbar-title>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-text-field :error="!isUserGoood" prepend-icon="person" name="login" label="Login" type="text" v-model="username" @keydown.enter="login"></v-text-field>
                <v-text-field :error="!isPasswordGood" id="password" prepend-icon="lock" name="password" label="Password" type="password" v-model="password" @keydown.enter="login"></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn @click="signup">Signup</v-btn> -->
            <v-btn @click="login">Login</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { apiPost } from '../modules/query.js'
import storage, { setToken, getToken } from '../modules/token.js'

export default {
    name: 'CardLogin',
    data: function() {
        // console.log(storage.token);

        return {
            username: '',
            password: '',
            isUserGoood: true,
            isPasswordGood: true
        }
    },
    methods: {
        login: function() {
            apiPost('/api/login', {
                username: this.username,
                password: this.password
            }).then(res => {
                setToken(res.data);
                this.$router.push('/catalogs');
            }).catch(err => {
                console.log('Error on login', err);

                let data = err.response.data;
                this.isUserGoood = data.userIsGood;
                this.isPasswordGood = data.passwordIsGood;
            });
        },
        // signup: function() {
        //     axios.post('http://localhost:3000/api/signup', {
        //         username: this.username,
        //         password: this.password
        //     }).then(res => {
        //         console.log(res);
        //     }).catch(err => {
        //         console.log(err);
        //     });
        // }
    }
}
</script>