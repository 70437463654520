<template>
  <v-container grid-list-md fill-height>
    <v-layout justify-center align-center v-if="!product.ean">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-layout>
    <v-btn fab fixed bottom right color="primary" @click="translateComments()">
      <v-icon v-if="selectedComments.length" dark>translate</v-icon>
      <v-icon v-if="!selectedComments.length" dark>add</v-icon>
    </v-btn>
    <v-layout v-if="product.ean" fill-height>
      <v-flex>
        <v-card>
          <v-toolbar flat color="primary" dark>
            <v-btn icon :to="catalogId ? '/catalog/' + catalogId : '/products'">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{product.name[displayLanguage]}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <input
              type="color"
              :id="product.ean + '-color'"
              @change="changeColor"
              v-model="productColor"
            />
            <v-flex xs2>
              <v-text-field v-model="productColor" @change="changeColor"></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-select v-model="displayLanguage" :items="productOriginLanguages"></v-select>
            </v-flex>
          </v-toolbar>
          <v-layout wrap>
            <v-flex xs4>
              <v-img :src="productImageLink" contain></v-img>
            </v-flex>
            <v-flex xs6 offset-xs1>
              <v-layout wrap>
                <v-flex xs6>
                  <v-text-field v-model="productSubtitle" @change="setCaption" label="Caption"></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-select
                    v-model="productCateg"
                    :items="clientCategs"
                    item-value="id"
                    item-text="name"
                    @change="changeCateg"
                    label="Category"
                  ></v-select>
                  <v-text-field v-if="product.categoryId" v-model="productCateg" readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-card-title>
                <span>
                  <h3>{{product.category[displayLanguage]}}</h3>
                  <div class="caption">
                    <u>ean:</u>
                    {{product.ean}}
                  </div>
                </span>
                <v-spacer></v-spacer>
                <span v-if="comments && comments.length">{{displayLanguage}} :</span>
                <v-rating
                  v-if="comments && comments.length"
                  small
                  dense
                  readonly
                  v-model="product.global_score[displayLanguage]"
                  half-icon
                ></v-rating>
                <!-- </span> -->
              </v-card-title>
              <v-card-text>{{product.subtitle[displayLanguage]}}</v-card-text>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
              <v-layout wrap>
                <v-flex xs12>
                  <!-- <search comments component> -->
                  <v-layout align-center>
                    <v-flex offset-xs1 xs2>
                      <v-rating dense clearable v-model="search.rating"></v-rating>
                    </v-flex>
                    <v-flex xs2>
                      <!-- <v-select v-model="search.language" :items="['All'].concat(languages)"></v-select> -->
                      <v-autocomplete
                        :items="[{code: 'all', name: 'All'}].concat(languages)"
                        item-text="name"
                        item-value="code"
                        v-model="search.language"
                      ></v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex>
                      <v-pagination
                        v-model="search.page"
                        total-visible="4"
                        :length="search.nbPages"
                      ></v-pagination>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        v-model="selectAll"
                        label="Select All"
                        hide-details
                        class="no-margin"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <!-- </search comments component> -->
                </v-flex>
                <v-flex
                  v-if="(comments && !comments.length) && (translatedComments && !translatedComments.length)"
                  xs12
                  class="text-xs-center"
                >No comments</v-flex>
                <v-flex>
                  <v-layout
                    class="comments-list"
                    wrap
                    v-if="(comments && comments.length) || (translatedComments && translatedComments.length)"
                  >
                    <!-- <v-flex xs4 v-for="comment in comments">
                                            <Comment :comment="comment" :catalogId="catalogId" @translationSelect="pushToTranslate" />
                                        </v-flex>
                                        <v-flex xs4 v-for="comment in translatedComments">
                                            <Comment :isTranslation="true" :comment="comment" :catalogId="catalogId" />
                    </v-flex>-->
                    <v-flex xs4 v-for="comment in commentsList">
                      <Comment
                        :isTranslation="!!comment.text"
                        :comment="comment"
                        :catalogId="catalogId"
                        @translationSelect="pushToTranslate"
                        :isSelected="selectedComments.indexOf(comment._id) >= 0"
                        @bookmark="bookmarkComment"
                        @unbookmark="unbookmarkComment"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    class="search-loader"
                    justify-center
                    align-center
                    v-if="comments === null"
                  >
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { apiGet, apiPost, apiPut } from "../modules/query.js";
import languages from "../assets/languages.json";
import allCategories from "../assets/categories.json";
import _ from "lodash";

import Comment from "../components/Comment.vue";

export default {
  components: {
    Comment
  },
  created: function() {
    let productId = this.$route.params.productId,
      catalogId = this.$route.query.catalogId;

    apiGet("/api/products/" + productId).then(result => {
      this.product = result.data;
      this.product.caption = this.product.caption || {};
      this.productColor = this.product.color || "#000000";
      if (this.product.categoryId) {
        this.productCateg = allCategories[this.product.categoryId].name.en_US;
      }

      console.log(this.product);

      this.searchTranslatedComments(this.product.ean).then(
        translatedComments => {
          if (catalogId) {
            apiGet("/api/catalogs/" + catalogId).then(result => {
              let catalog = result.data;

              if (catalog.bookmarks && catalog.bookmarks.length) {
                catalog.bookmarks.forEach(bookmarkId => {
                  let translatedComment = translatedComments
                    ? translatedComments.find(
                        translation => translation._id == bookmarkId
                      )
                    : null;
                  // Force binding
                  if (translatedComment) translatedComment.isBookmarked = true;
                });
              }
            });
          }
        }
      );

      this.searchComments().then(comments => {
        if (catalogId) {
          apiGet("/api/catalogs/" + catalogId).then(result => {
            let catalog = result.data;
            this.catalog = catalog;
            this.nbCurrentComments = this.comments.length;

            if (catalog.bookmarks && catalog.bookmarks.length) {
              catalog.bookmarks.forEach(bookmarkId => {
                let comment = comments.find(
                  comment => comment._id == bookmarkId
                );
                // Force Binding
                if (comment) comment.isBookmarked = true;
              });
            }
          });
        }
      });
    });
  },
  data: function() {
    return {
      search: {
        rating: 0,
        language: "all",
        page: 1,
        nbPages: 1
      },
      product: {},
      productColor: "#FFFFFF",
      productCateg: "",
      selectedLanguage: "",
      comments: null,
      catalog: null,
      catalogId: this.$route.query.catalogId || "",
      selectedComments: [],
      translatedComments: null,
      nbCurrentComments: null,
      languages: languages,
      selectAll: false,
      clientCategs: [
        { name: "Eye Makeup", id: 6014 },
        { name: "Mascara", id: 6015 },
        { name: "Brows", id: 6016 },
        { name: "Eyeliner", id: 6017 },
        { name: "Eye Shadow", id: 6018 },
        { name: "Lips", id: 6019 },
        { name: "Lipsticks", id: 6020 },
        { name: "Glossy Stain", id: 6021 },
        { name: "Lip Gloss", id: 6022 },
        { name: "Lip Liner", id: 6023 },
        { name: "Nails", id: 6027 },
        { name: "Nail Lacquer", id: 6029 },
        { name: "Face Makeup", id: 6030 },
        { name: "Concealer", id: 6031 },
        { name: "Face Powder", id: 6032 },
        { name: "Blush & Bronzers", id: 6033 },
        { name: "Foundation", id: 6035 },
        { name: "Primer", id: 6036 },
        { name: "Accessories", id: 6037 },
        { name: "Makeup Brushes", id: 6038 },
        { name: "Women's Fragrances", id: 6043 },
        { name: "Parisienne", id: 6044 },
        { name: "Opium", id: 6045 },
        { name: "Black Opium", id: 6046 },
        { name: "Mon Paris", id: 6047 },
        { name: "Paris", id: 6048 },
        { name: "Men's Fragrances", id: 6049 },
        { name: "L'Homme", id: 6050 },
        { name: "L'Homme Sport", id: 6051 },
        { name: "La Nuit De L'Homme", id: 6052 },
        { name: "L'Homme Ultime", id: 6053 },
        { name: "L'Homme Libre", id: 6054 },
        { name: "Y", id: 6055 },
        { name: "Le Vestiaire Des Parfums", id: 6059 },
        { name: "Skincare Collections", id: 6062 },
        { name: "Forever Youth Liberator", id: 6063 },
        { name: "Top Secrets", id: 6064 },
        { name: "Or Rouge", id: 6065 },
        { name: "Temps Majeur", id: 6066 },
        { name: "Forever Light Creator", id: 6080 },
        { name: "MAKEUP SETS", id: 6081 },
        { name: "BLANC PUR COUTURE", id: 6082 },
        { name: "Touche Éclat", id: 6083 },
        { name: "Pure Shots", id: 99991 }
      ]
    };
  },
  computed: {
    commentsList: function() {
      let list = (this.comments || [])
        .concat(this.translatedComments || [])
        .sort((a, b) => {
          return +new Date(b.timestamp) - +new Date(a.timestamp);
        });

      if (this.catalog && this.catalog.bookmarks) {
        this.catalog.bookmarks.forEach(commentId => {
          list.forEach(comment => {
            if (comment._id == commentId) {
              comment.isBookmarked = true;
            }
          });
        });
      }

      return list;
    },
    productOriginLanguages: function() {
      return Object.keys(this.product.src).sort();

      // return Array.from(new Set(languages.map(l => l.substr(0, 2))));
    },
    productLanguages: function() {
      if (this.translatedComments) {
        // console.log("tranlated comments", this.translatedComments);
        let commentsLanguages = Object.keys(this.product.src);
        let tranlateLanguages = [];
        this.translatedComments.forEach(translatedComment =>
          tranlateLanguages.push(translatedComment.language)
        );
        return commentsLanguages.concat(tranlateLanguages).sort();
      }
      return [];
    },
    displayLanguage: {
      get: function() {
        return this.selectedLanguage
          ? this.selectedLanguage
          : this.productOriginLanguages[0];
      },
      set: function(newLanguage) {
        this.selectedLanguage = newLanguage;
      }
    },
    productSubtitle: {
      get: function() {
        return this.product.caption[this.displayLanguage];
      },
      set: function(subtitle) {
        this.product.caption[this.displayLanguage] = subtitle;
      }
    },
    productImageLink: function() {
      console.log(this.product);
      if (!this.product.s3_packshot) return "https://test.com";
      let s3_packshot = this.product.s3_packshot,
        imgSources = Object.keys(s3_packshot);

      return s3_packshot["en-US"]
        ? s3_packshot[this.displayLanguage]
        : s3_packshot[imgSources[0]];
    }
  },
  watch: {
    "search.rating": function() {
      this.search.page = 1;
      this.searchComments();
      this.searchTranslatedComments(this.product.ean);
      //this.checkBookmarks();
    },
    "search.page": function() {
      this.searchComments();
      this.searchTranslatedComments(this.product.ean);
      //this.checkBookmarks();
    },
    "search.language": function() {
      this.search.page = 1;
      this.searchComments();
      this.searchTranslatedComments(this.product.ean);
      //this.checkBookmarks();
      // console.log(this.translatedComments, this.comments)
    },
    selectAll: function() {
      if (this.selectAll) {
        this.selectedComments = this.comments.map(comment => comment._id);
        //.concat(this.translatedComments.map(translation => translation._id));
      } else this.selectedComments = [];
    }
  },
  methods: {
    changeColor: function() {
      if (this.productColor[0] == "#" && this.productColor.length == 7) {
        apiPut("/api/products/" + this.product._id, {
          color: this.productColor
        });
      }
    },
    changeCateg: function() {
      apiPut("/api/products/" + this.product._id, {
        categoryId: this.productCateg
      });
    },
    setCaption: function() {
      apiPut("/api/products/" + this.product._id, {
        caption: this.product.caption
      });
    },
    searchComments: function() {
      return new Promise((resolve, reject) => {
        let productId = this.$route.params.productId;

        // This activate loading
        // let tempComments = this.comments
        this.comments = null;
        this.selectedComments = [];

        apiGet(
          "/api/products/" + productId + "/comments",
          {},
          {
            page: this.search.page,
            rating: this.search.rating || null,
            lang_short:
              this.search.language == "all" ? null : this.search.language,
            productId: this.product.productId,
            productEan: this.product.productId ? null : this.product.ean
          }
        )
          .then(result => {
            // 204 return ""
            let comments = result.data.comments || [];
            this.comments = comments.map(comment => {
              comment.isBookmarked = false;
              return comment;
            });

            this.search.nbPages = result.data.count
              ? Math.floor(result.data.count / 20) + 1
              : 0;

            resolve(this.comments);
          })
          .catch(err => console.log("Err getting comments", err));
      });
    },
    bookmarkComment: function(commentId) {
      if (this.catalog && this.catalog.bookmarks) {
        this.catalog.bookmarks.push(commentId);
      }
    },
    unbookmarkComment: function(commentId) {
      if (this.catalog && this.catalog.bookmarks) {
        this.catalog.bookmarks = _.pull(this.catalog.bookmarks, commentId);
      }
    },
    searchTranslatedComments: function(productEan) {
      return new Promise((resolve, reject) => {
        let language = this.search.language;

        apiGet(
          "/api/translations/" + productEan,
          {},
          {
            page: this.search.page,
            rating: this.search.rating || null,
            language: this.search.language === "all" ? null : language,
            "productIdentity.productId": this.product.productId || "notAnId"
          }
        )
          .then(result => {
            let translatedComments = result.data || [];
            // if (!this.translatedComments) {
            this.translatedComments = translatedComments.map(comment => {
              comment.isBookmarked = false;
              // console.log("comment", comment);
              return comment;
            });
            // }
            resolve(this.translatedComments);
          })
          .catch(err => console.log("Err getting translated comments", err));
        // }
        // else {
        //   this.translatedComments = null;
        //   resolve(this.translatedComments);
        // }
      });
    },
    translateComments: function() {
      this.$router.push({
        path: "/translate",
        query: {
          comments: this.selectedComments,
          productId: this.product._id
        }
      });
    },
    pushToTranslate: function(checkState, idComment) {
      if (checkState) this.selectedComments.push(idComment);
      else {
        let index = this.selectedComments.indexOf(idComment);
        this.selectedComments.splice(index, 1);
      }
    }
    // checkBookmarks: function() {
    //     this.catalog.bookmarks.forEach(commentId => {
    //         if (this.comments && this.comments.length) {
    //             this.comments.forEach(comment => {
    //                 if (comment._id == commentId) {
    //                     comment.isBookmarked = true;
    //                 }
    //             });
    //         }
    //         if (this.translatedComments && this.translatedComments.length) {
    //             this.translatedComments.forEach(comment => {
    //                 if (comment._id == commentId) {
    //                     comment.isBookmarked = true;
    //                 }
    //             });
    //         }
    //     });
    // }
  }
};
</script>
<style scoped>
.comments-list {
  max-height: 50vh;
  min-height: 500px;
  padding: 5px;
  overflow: scroll;
}

.search-loader {
  min-height: 200px;
}

.no-margin {
  margin-top: 0px !important;
}
</style>