<template>
    <v-container grid-list-md>
        <v-layout justify-center align-center v-if="!product._id">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-layout>
        <v-layout wrap v-if="product._id">
            <v-flex xs9>
                <h2>
                    <v-btn icon @click="$router.go(-1)">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    {{product.name[comments[0].lang] || product.name[Object.keys(product.name)[0]]}}
                </h2>
            </v-flex>
            <v-flex xs3>
                <v-autocomplete solo :items="languages" item-text="name" item-value="code" v-model="translationLanguage" @change="getTranslations" label="Translation language"></v-autocomplete>
            </v-flex>
            <v-flex xs6 v-for="comment in comments">
                <v-card>
                    <v-toolbar dense color="primary" dark>
                        <!-- <v-btn icon @click="$router.go(-1)">
    <v-icon>arrow_back</v-icon>
</v-btn>
<v-toolbar-title>Product Name: {{product.name[comment.lang]}}</v-toolbar-title>
<v-spacer></v-spacer> -->
                        <v-spacer></v-spacer>
                        <v-rating color="white" small dense readonly v-model="comment.rating"></v-rating>
                    </v-toolbar>
                    <v-card-actions>
                        <v-textarea readonly name="commentTest" :hint="comment.translation.hint" persistent-hint label="Source Text" :value="comment.comment"></v-textarea>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-textarea name="commentTest" label="Translated Text" v-model="comment.translation.text"></v-textarea>
                    </v-card-actions>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <v-flex>
                            <v-autocomplete :items="languages" item-text="name" item-value="code" v-model="comment.translation.language" @change="getTranslation(comment)" label="Translation language"></v-autocomplete>
                        </v-flex> -->
                        <v-btn :disabled="!isTranslationValid(comment)" :loading="comment.translation.saveState == 'saving'" @click="saveTranslation(comment)" :color="comment.translation.saveState == 'saved' ? 'success' : 'primary'">{{comment.translation.saveState == 'saved' ? 'Saved' : 'Save Translation'}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-btn fab fixed bottom right dark color="primary" icon @click="saveAllTranslation">
            <v-icon>save</v-icon>
        </v-btn>
    </v-container>
</template>
<script>
import { apiGet, apiPost, apiPut } from '../modules/query.js'
import languages from '../assets/languages.json'

export default {
    created: function() {
        // console.log('Translate Page', this.$route);

        let comments = this.$route.query.comments;
        if (comments && !comments.map) {
            comments = [comments];
        }

        if (comments && comments.length) {
            Promise.all(comments.map(commentId => {
                return apiGet('/api/comments/' + commentId).then(result => {
                    let comment = result.data

                    // console.log(comment);

                    comment.translation = {
                        text: '',
                        language: this.$route.query.language || '',
                        saveState: 'unsaved',
                        hint: ''
                    }

                    this.comments.push(comment);
                    // console.log('Comment', this.comment);
                    if (!this.queryProduct) {
                        this.queryProduct = true;

                        let productId = this.$route.query.productId;

                        apiGet('/api/products/' + productId).then(result => {
                            this.product = result.data;

                            // console.log('Product:', this.product);
                        });
                    }
                });
            }));
        } else {
            this.comments.push({
                rating: 5,
                lang: 'en',
                comment: '',
                timestamp: new Date(),
                translation: {
                    text: '',
                    language: this.$route.query.language || '',
                    saveState: 'unsaved',
                    hint: ''
                }
            });

            if (!this.queryProduct) {
                this.queryProduct = true;

                let productId = this.$route.query.productId;

                apiGet('/api/products/' + productId).then(result => {
                    this.product = result.data;

                    // console.log('Product:', this.product);
                });
            }
        }
    },
    data: function() {
        return {
            languages: languages,
            translationLanguage: '',
            comments: [],
            product: {},
            queryProduct: false
        }
    },
    methods: {
        saveAllTranslation: function() {
            this.comments.forEach(comment => {
                if (comment.translation.text && comment.translation.text.length) {
                    this.saveTranslation(comment);
                }
            });
        },
        saveTranslation: function(comment) {
            let translation = {
                // title: this.translation.title,
                text: comment.translation.text,
                language: this.translationLanguage || 'en',
                timestamp: comment.timestamp,
                productIdentity: {
                    ean: this.product.ean,
                    productId: this.product.productId
                },
                src: {
                    // title: this.comment.title,
                    text: comment.comment,
                    rating: comment.rating,
                    language: comment.lang,
                    username: comment.username,
                    title: comment.title
                }
            }

            if (comment.savedTranslation) {
                let savedText = comment.savedTranslation.text;

                if (savedText != translation.text) {
                    comment.translation.saveState = 'saving';
                    apiPut('/api/translations/' + comment.savedTranslation.id, translation).then(result => {
                        translation.id = comment.savedTranslation.id;
                        comment.translation.saveState = 'saved';
                        comment.savedTranslation = translation;
                    }).catch(console.log);
                }
            } else {
                comment.translation.saveState = 'saving';
                apiPost('/api/translations', translation).then(result => {
                    comment.translation.saveState = 'saved';
                    translation.id = result.data;
                    console.log(result);
                    comment.savedTranslation = translation;
                }).catch(console.log);
            }
        },
        getTranslations: function() {
            // console.log('getTranslation', comment);
            this.comments.forEach(comment => {
                if (this.translationLanguage) {
                    apiPost('/api/translate', {
                        text: comment.comment,
                        language: this.translationLanguage
                    }).then(result => {
                        let translatedText = result.data[0];

                        if (translatedText.length) {
                            if (!comment.translation.text.length) {
                                comment.translation.text = translatedText;
                            }

                            comment.translation.hint = translatedText;
                        }
                    }).catch(console.log);
                }
            });
        },
        isTranslationValid: function(comment) {
            if (comment.translation.text.length == 0) {
                return false;
            } else return true;
        }
    }
}
</script>
<style scoped>
</style>