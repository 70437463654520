<template>
    <div>
        <v-container grid-list-md fluid v-if="catalog && allProducts">
            <v-text-field :rules="[rules.required]" ref="titleCatalog" :color="lockTitle ? 'primary' : 'green'" :readonly="lockTitle" v-model="catalog.name" outline append-icon="create" label="Name" @click:append="editTitle" @keydown.enter="editTitle"></v-text-field>
            <v-flex offset-xs10 xs2 align-self-end class="text-xs-right">
                <v-btn class="mt-0" color="white" icon @click="mainDisplayTile = false">
                    <v-icon>reorder</v-icon>
                </v-btn>
                <v-btn class="mt-0" color="white" icon @click="mainDisplayTile = true">
                    <v-icon>view_module</v-icon>
                </v-btn>
            </v-flex>
            <ProductsList :displayTile="mainDisplayTile" v-if="catalog.products.length > 0" :products="chooseLangDefault(catalog.products)" remove edit @remove="removeEvt" @edit="editEvt" />
            <v-layout v-else justify-center align-center style="height: 75vh">
                <h1>Catalog is empty</h1>
            </v-layout>
            <v-snackbar v-model="snackbar" :timeout="5000" top>
                {{snackbarText}}
                <v-btn color="white" flat @click="snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
            <v-layout row justify-center>
                <div class="text-xs-center">
                    <v-dialog v-model="dialogConfirmDelete" width="500">
                        <v-card>
                            <v-card-title class="headline error" style="color:white" primary-title>
                                Warning
                            </v-card-title>
                            <v-card-text>
                                You are about to remove a product from catalog.
                                <br /><br />
                                <b>Are you sure ?</b>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" outline @click="dialogConfirmDelete = false;">
                                    Cancel
                                </v-btn>
                                <v-btn color="error" @click="deleteFromCatalog">
                                    Delete
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <v-dialog v-model="dialog" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on }">
                        <v-btn fab fixed bottom right dark color="primary" v-on="on" icon>
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-btn fab fixed bottom right dark color="primary" icon @click="saveProductsCatalog">
                            <v-icon>save</v-icon>
                        </v-btn>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Compose your catalog</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-actions>
                            <v-container grid-list-md>
                                <SearchAndPaginate v-model="queryProperties" :pagination="!noProduct" />
                            </v-container>
                        </v-card-actions>
                        <v-card-text class="pt-0" style="overflow: auto;min-height: 100vh;background-color:white;" v-if="!isLoading">
                            <v-container grid-list-md fluid class="pt-0">
                                <v-layout row wrap v-if="allProducts && !isLoading && !noProduct">
                                    <v-flex xs12>
                                        <ProductsList :products="chooseLangDefault(allProducts)" v-model="selectedIdProducts" :select="selectedIdProducts" :displayTile="queryProperties.displayTile" />
                                    </v-flex>
                                </v-layout>
                                <v-layout v-else-if="noProduct" justify-center align-center style="height: 75vh">
                                    <h1>No products found</h1>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-text v-else>
                            <v-layout justify-center align-center style="height: 75vh">
                                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-layout>
        </v-container>
        <v-container v-else grid-list-md fill-height>
            <v-layout justify-center align-center style="height: 90vh">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import ProductsList from '../components/ProductsList.vue'
import SearchAndPaginate from '../components/SearchAndPaginate.vue'
import { apiGet, apiPut, apiPost } from "../modules/query.js"
import _ from "lodash"

export default {
    components: {
        SearchAndPaginate,
        ProductsList
    },
    data: function() {
        return {
            mainDisplayTile: true,
            queryProperties: SearchAndPaginate.props.queryProperties.default(),
            page: 1,
            dialogConfirmDelete: false,
            catalog: null,
            allProducts: null,
            dialog: false,
            selectedIdProducts: [],
            lang: this.$route.params.lang || "en",
            idToDelete: null,
            lockTitle: true,
            snackbar: false,
            snackbarText: "",
            isLoading: false,
            noProduct: false,
            rules: {
                required: value => !!value || 'Required.',
            }
        }
    },
    watch: {
        async queryProperties(val) {
            this.isLoading = true
            await this.refreshProductsPage(val.page)
            this.isLoading = false
        }
    },
    methods: {
        async refreshProductsPage(page, textQuery) {
            try {
                let { displayTile, totalPages, ...query } = this.queryProperties
                query = _.pickBy(query)
                if (query.categories.length == 0)
                    delete query.categories
                if (query.searchEan) {
                    query.ean = query.searchEan
                    delete query.searchEan
                }
                let allProducts = await apiGet("/api/products", null, query);
                if (allProducts.status != 200) {
                    this.noProduct = true
                } else {
                    this.noProduct = false
                    this.allProducts = allProducts.data.products;
                    this.queryProperties.page = allProducts.data.meta.page
                    this.queryProperties.totalPages = allProducts.data.meta.totalPages
                }
            } catch (e) {
                this.snackbarText = e
                this.snackbar = true
            }

        },

        chooseLangDefault: function(products) {
            return products.map(product => {
                let languages = Object.keys(product.name)
                let lang = languages.find(product => product.startsWith("fr") || product.startsWith("en"))
                if (!lang)
                    lang = languages[0]
                let newObj = _.cloneDeep(product)
                let keys = Object.keys(product);
                for (let k of keys) {

                    if (product[k] && product[k][lang]) {
                        let temp = product[k][lang]
                        newObj[k] = temp
                    } else {
                        // Only good for img not for text.
                        if (k == 's3_packshot') {
                            let imgPackshotKeys = Object.keys(product[k]);
                            // Select first image available.
                            newObj[k] = product[k][imgPackshotKeys[0]]
                        }
                    }
                }
                return newObj

            })
        },

        async saveProductsCatalog() {
            try {
                let arrayProds = this.selectedIdProducts.map(e => {
                    return { ean: e, favoriteComments: [] }
                });
                let idCatalog;
                if (this.$route.params.id) {
                    idCatalog = this.$route.params.id
                    await apiPut("/api/catalogs/" + this.catalog._id, { $set: { products: arrayProds } })
                } else {
                    let tempCatalog = _.cloneDeep(this.catalog)
                    tempCatalog.products = arrayProds
                    let newCatalog = await apiPost("/api/catalogs", tempCatalog)
                    idCatalog = newCatalog.data.insertedId
                    this.$router.push({ path: "/catalog/" + idCatalog })
                }
                let catalog = await apiGet("/api/catalogs/" + idCatalog);
                this.catalog = catalog.data
                this.snackbarText = "Catalog updated !"
                this.snackbar = true
            } catch (e) {
                this.snackbarText = e
                this.snackbar = true
            }

            this.dialog = false;

        },
        async editTitle() {

            if (this.lockTitle) {

                this.lockTitle = false
                this.$refs.titleCatalog.focus()
            } else {
                this.lockTitle = true
                try {
                    if (this.$route.params.id) {
                        await apiPut("/api/catalogs/" + this.catalog._id, { $set: { name: this.catalog.name } })
                    }
                    this.snackbarText = "Catalog title updated !"
                    this.snackbar = true
                } catch (e) {
                    this.snackbarText = e
                    this.snackbar = true
                }

            }
        },
        async removeEvt(evt) {
            this.idToDelete = evt
            this.dialogConfirmDelete = true
        },
        editEvt(id) {
            this.$router.push({ name: 'Product', params: { productId: id }, query: { catalogId: this.catalog._id } })
        },
        async deleteFromCatalog() {
            try {

                this.catalog.products = this.catalog.products.filter(e => e.ean != this.idToDelete)
                this.selectedIdProducts = this.catalog.products.filter(e => e.ean != this.idToDelete).map(e => e.ean)
                await apiPut("/api/catalogs/" + this.catalog._id, { $pull: { products: { ean: this.idToDelete } } })
                this.idToDelete = null
                this.dialogConfirmDelete = false;
                this.snackbarText = "Product removed !"
                this.snackbar = true
            } catch (e) {
                this.snackbarText = e
                this.snackbar = true
            }

        },
    },
    async created() {
        try {
            if (this.$route.params.id) {
                let catalog = await apiGet("/api/catalogs/" + this.$route.params.id);
                this.catalog = catalog.data;
                this.selectedIdProducts = this.catalog.products.map(e => e.ean)
            } else {
                this.catalog = { name: this.$route.params.name, language: this.$route.params.lang, products: [] }
            }
            await this.refreshProductsPage(this.queryProperties.page)
        } catch (e) {
            this.snackbarText = e
            this.snackbar = true
        }
    }

}
</script>
<style>
.v-input__slot {
    margin-bottom: 0;
}
</style>