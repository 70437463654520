<template>
    <v-app>
        <v-toolbar color="elevation-0 primary" class="white--text" fixed app clipped-right>
            <v-toolbar-side-icon class="white--text" @click.stop="drawer = !drawer"></v-toolbar-side-icon>
            <v-toolbar-title>{{$route.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn flat @click="dialog=true" class="white--text">
                    <v-icon>search</v-icon> &nbsp; Make a request
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-navigation-drawer v-model="drawer" app fixed>
            <v-toolbar flat color="elevation-0 primary">
                <v-list>
                    <v-list-tile>
                        <router-link to="/landing">
                            <v-list-tile-title class="title white--text">
                                Bweez
                            </v-list-tile-title>
                        </router-link>
                    </v-list-tile>
                </v-list>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list v-if="loggedIn" dense class="pt-0">
                <template v-for="(item, i) in items">
                    <v-list-tile v-if="item.role.indexOf(user.role) >= 0" :key="i" :to="item.to" router exact>
                        <v-list-tile-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>
                <v-list-tile @click="refreshCache">
                    <v-list-tile-action>
                        <v-icon :color="cacheRefreshing ? 'success' : ''">autorenew</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>{{ cacheRefreshing ? 'Cache Refreshing' : 'Refresh' }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-tile @click="disconnect">
                    <v-list-tile-action>
                        <v-icon>power_settings_new</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>Disconnect</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-navigation-drawer>
        <v-content>
            <router-view></router-view>
        </v-content>
        <v-snackbar v-model="snackbar" :timeout="5000" top>
            {{snackbarMsg}}
            <v-btn color="primary" flat @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
        <div class="text-xs-center">
            <v-dialog v-model="dialog" width="500">
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>
                        Scraping Request
                    </v-card-title>
                    <v-card-text>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-text-field v-model="urlToScrap" label="Url" placeholder="Paste the URL of the website you would like to scrap " :rules="[rules.required]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="sender" label="Email" placeholder="Enter your email adress" :rules="[rules.required, rules.email]"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" flat @click="dialog=false">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" depressed @click="sendMail">
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-app>
</template>
<script>
import axios from "axios"
import { apiPost, apiGet, getSelf, logout } from "./modules/query.js"
import { getToken, onAuthChange } from "./modules/token.js"

export default {
    created: function() {
        onAuthChange(authToken => {
            if (authToken) {
                getSelf().then(user => {
                    this.userLoggedIn = true;
                    this.user = user
                });
            } else {
                this.user = {};
                this.$router.push('/login');
            }
        });

        if (!getToken() && !this.$route.query.token) {
            this.$router.push('/login');
        } else {
            getSelf().then(user => {
                this.userLoggedIn = true;
                this.user = user;
            });
        }
    },
    data() {
        return {
            title: null,
            drawer: false,
            dialog: false,
            snackbar: false,
            snackbarMsg: "",
            sender: "",
            user: {},
            userLoggedIn: false,
            urlToScrap: "",
            lastRefresh: 0,
            items: [{
                    icon: 'bookmarks',
                    title: 'Catalogs',
                    to: '/catalogs',
                    role: ['user', 'admin', 'super admin']
                },
                {
                    icon: 'bookmark',
                    title: 'Products',
                    to: '/products',
                    role: ['user', 'admin', 'super admin']
                },
                {
                    icon: 'perm_identity',
                    title: 'Users',
                    to: '/users',
                    role: ['admin', 'super admin']
                }
            ],
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            }

        }
    },
    methods: {
        sendMail: async function() {
            this.dialog = false;
            console.log("Send mail");
            try {
                await apiPost("/api/request", { sender: this.sender, urlToScrap: this.urlToScrap })
                this.snackbar = true
                this.snackbarMsg = "Your request has been send !"
                this.sender = ""
                this.urlToScrap = ""
            } catch (e) {
                this.snackbarMsg = "An error occured :( Please retry !"
                console.log(e)
            }

        },
        refreshCache: function() {
            apiGet('/api/refresh_cache');
            this.lastRefresh = Date.now();
        },
        disconnect: function() {
            logout();
        }
    },
    computed: {
        loggedIn: function() {
            return this.user.username ? true : false;
        },
        cacheRefreshing: function() {
            let tenMinutes = 1000 * 60 * 10;

            return Date.now() - tenMinutes < this.lastRefresh;
        }
    }
}
</script>
<style>
.page-enter-active,
.page-leave-active {
    transition: opacity .5s;
}

.page-enter,
.page-leave-to {
    opacity: 0;
}

/* Fix margin on text area */
.v-text-field__details .v-messages__wrapper {
    margin-top: 5px !important;
}
</style>