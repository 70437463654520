<template>
  <v-container grid-list-md>
    <SearchAndPaginate v-model="queryProperties" :pagination="!noProduct" />
    <ProductsList
      v-if="allProducts && !isLoading && !noProduct"
      :displayTile="queryProperties.displayTile"
      :products="chooseLangDefault(allProducts)"
      edit
      @edit="editEvt"
    />
    <v-layout v-else-if="noProduct" justify-center align-center style="height: 75vh">
      <h1>No products found</h1>
    </v-layout>
    <v-layout v-else justify-center align-center style="height: 75vh">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-layout>
    <v-snackbar v-model="snackbar" :timeout="5000" top>
      {{snackbarText}}
      <v-btn color="primary" flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import ProductsList from "../components/ProductsList.vue";
import { apiGet } from "../modules/query.js";
import _ from "lodash";
import SearchAndPaginate from "../components/SearchAndPaginate.vue";

export default {
  components: {
    ProductsList,
    SearchAndPaginate
  },
  data: function() {
    return {
      queryProperties: SearchAndPaginate.props.queryProperties.default(),
      snackbar: false,
      snackbarText: "",
      allProducts: null,
      page: 1,
      totalPages: 6,
      isLoading: true,
      lang: "en",
      searchText: "",
      searchEan: "",
      comment: {},
      noProduct: false
    };
  },
  watch: {
    async queryProperties(val, valOld) {
      this.isLoading = true;
      await this.refreshProductsPage(val.page);
      this.isLoading = false;
    }
  },
  methods: {
    async refreshProductsPage(page, textQuery) {
      try {
        let { displayTile, totalPages, ...query } = this.queryProperties;
        query = _.pickBy(query);
        if (query.categories.length == 0) delete query.categories;
        if (query.searchEan) {
          query.ean = query.searchEan;
          delete query.searchEan;
        }

        let allProducts = await apiGet("/api/products", null, query);
        if (allProducts.status != 200) {
          this.noProduct = true;
        } else {
          this.noProduct = false;
          this.allProducts = allProducts.data.products;
          this.queryProperties.page = allProducts.data.meta.page;
          this.queryProperties.totalPages = allProducts.data.meta.totalPages;
        }
      } catch (e) {
        this.snackbarText = e;
        this.snackbar = true;
      }
    },
    chooseLangDefault: function(products) {
      return products.map(product => {
        let languages = Object.keys(product.name);

        let lang = languages.find(
          product => product.startsWith("fr") || product.startsWith("en")
        );

        if (!lang) lang = languages[0];
        let newObj = _.cloneDeep(product);
        let keys = Object.keys(product);

        for (let k of keys) {
          //console.log("keys: ", k)
          if (product[k] && product[k][lang]) {
            let temp = product[k][lang];
            newObj[k] = temp;
          } else {
            // Only good for img not for text.
            if (k == "s3_packshot" && product[k] !== null) {
              let imgPackshotKeys = Object.keys(product[k]);
              // Select first image available.
              newObj[k] = product[k][imgPackshotKeys[0]];
            }
          }
        }
        return newObj;
      });
    },
    editEvt(id) {
      this.$router.push({ name: "Product", params: { productId: id } });
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await this.refreshProductsPage(this.queryProperties.page);
      this.isLoading = false;
    } catch (e) {
      this.snackbarText = e;
      this.snackbar = true;
    }
  }
};
</script>